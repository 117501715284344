<template>
  <v-container fluid class="full-fill pa-0">
    <v-row class="full-fill">
      <!-- Search -->
      <v-col cols="2.5" class="d-none d-sm-flex ma-0 pa-5">
        <Search />
      </v-col>
      <!-- Content -->
      <v-col class="col-sm-7 ma-0 pa-5 pl-5 pr-5 pl-sm-0 pr-sm-0">
        <v-row class="full-fill">
          <v-col class="d-none d-sm-flex ma-0 pa-5">
          </v-col>
          <v-col align="center" class="col-sm-8 ma-0 pa-0">
            <v-card class="pa-5 w-100" fluid>
              <h1>Contact Us</h1>
              <v-form @submit.prevent="submit" ref="form" v-model="valid">
                <v-text-field v-model="name" label="Name" required></v-text-field>
                <v-text-field v-model="subject" label="Subject" required></v-text-field>
                <v-text-field v-model="emailAddress" :rules="emailRules" label="Email Address" required></v-text-field>
                <v-textarea v-model="message" label="Message" required></v-textarea>
                <v-btn color="blue darken-3 white--text" type="submit" :loading="loading">Submit</v-btn>
              </v-form>
              <div>
                <div v-if="isSendingFailure" class="error--text mt-3">{{ error }}</div>
                <div class="mt-3" v-else-if="isSendingSuccess">Your message was sent successfully. We will respond as soon as we can.</div>
              </div>
            </v-card>
          </v-col>
          <v-col class="d-none d-sm-flex ma-0 pa-5">
          </v-col>
        </v-row>
      </v-col>
      <!-- Ads -->
      <v-col cols="2.5" class="d-none d-sm-flex ma-0 pa-5">
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import Search from '@/components/Search'

  export default {
    name: 'Contact',

    components: {
      Search
    },

    data () {
      return {
        emailAddress: '',
        emailRules: [
          v => !!v || 'E-mail is required.',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid.'
        ],
        isSendingSuccess: false,
        isSendingFailure: false,
        loading: false,
        message: '',
        name: '',
        subject: '',
        valid: false
      }
    },

    methods: {
      // Send data off to nodemailer function
      submit () {
        this.error = ''
        this.$refs.form.validate()
        if (this.valid && !this.loading) {
          this.loading = true
          const { name, emailAddress, subject, message } = this
          axios({
            url: 'https://us-central1-dev-honor-tag-vets.cloudfunctions.net/api/contact',
            data: {
              emailAddress,
              message,
              name,
              subject
            },
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            method: 'POST'
          })
          .then((resp) => {
            this.loading = false
            this.$refs.form.reset()
            if (resp.data === 'success') {
              this.isSendingSuccess = true
            } else {
              this.isSendingFailure = true
              this.error = resp
            }
          })
          .catch((error) => {
            this.error = error
            this.loading = false
            this.$refs.form.reset()
          })
        }
      }
    }
  }
</script>

<style scoped>
  .full-fill {
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
  }
</style>
